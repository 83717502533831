import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useFetch from '../../utils/useFetch';
import './list.css';

const ItemList = () => {
    const navigate = useNavigate();
    const params = useParams();
    const location = params.name;
    const { state } = useLocation();
    const { areaId } = state;
    const [localBuildings, setLocalBuildings] = useState([]);
    const { data: buildings, loading } = useFetch(`/areas/${areaId}`);

    useEffect(() => {
        if (buildings) {
            setLocalBuildings(buildings.data);
        }
    }, [buildings]);

    const bookBuilding = (item) => {
        navigate(`/checkout/${location}/${item.id}`, {
            state: { ...state, buildingName: item.name, buildingId: item.id },
        });
    };

    return (
        <Container className="list-container" fluid>
            <Container>
                {loading ? (
                    <div className="text-center py-5">
                        <Spinner animation="border" role="status" style={{ color: "#00B8A9" }}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <Row>
                        {localBuildings?.map(item => (
                            <Col key={item.id} xs={12} sm={6} md={3} className="mb-4">
                                <Card className="h-100">
                                    <div style={{ overflow: 'hidden', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Card.Img
                                            variant="top"
                                            src={item.images[0]}
                                            alt={item.name}
                                            style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'cover', scale: '1.17' }}
                                        />
                                    </div>
                                    <Card.Body style={{ display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
                                        <Card.Title>{item.name}</Card.Title>
                                        <Card.Text>{item.description}</Card.Text>
                                        <div className='d-flex align-items-center w-100 justify-content-between'>
                                            <Card.Text style={{ margin: 0 }}>
                                                <span>📍</span>
                                                <span>
                                                    <a href={item.mapLocation} target="_blank" rel="noopener noreferrer" style={{ color: "#00B8A9", textDecoration: "none" }}>View Location</a>
                                                </span>
                                            </Card.Text>
                                            <Button
                                                variant="primary"
                                                style={{
                                                    fontWeight: "bold",
                                                    backgroundColor: "#F15A29",
                                                    border: 0,
                                                }}
                                                onClick={() => bookBuilding(item)}
                                            >
                                                Book
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                )}
            </Container>
        </Container>
    );
};

export default ItemList;
