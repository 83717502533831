import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './checkout.css';
import dayjs from "dayjs";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { FaCalendarAlt, FaClock, FaDollarSign, FaRedoAlt, FaCalendarCheck, FaTimesCircle } from "react-icons/fa";
import { useContext } from 'react';
import { AuthContext } from '../../utils/AuthContext';
import useFetch from '../../utils/useFetch';


const BookingConfirmation = () => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const { state, pathname } = useLocation();
    const { arrivingDate, leavingDate, buildingName } = state;
    // const { name, id } = useParams();
    const { buildingId } = state;
    const { data: buildingResponse, loading } = useFetch(`/buildings/${buildingId}`);
    const buildingData = buildingResponse.data
    const buildingImage = buildingData?.images[0]

    const bookParking = () => {
        if (user) {
            const id = Math.random().toString().split("0.")[1]
            navigate(`/issue/${id}`, {
                state: { ...state },
            });
        } else {
            navigate(`/auth/login?redirectTo=${pathname}`, {
                state: { ...state },
            })
        }
    }

    return (
        <Container className="list-container" fluid>
            <Container className="py-5">
                <Row>
                    <Col md={8}>
                        <Card className="booking-details-card">
                            <Card.Body>
                                <h3 className="section-title">Confirm your booking and pay</h3>
                                <hr />

                                <h5 className="subsection-title">Booking details</h5>
                                <Row className="mt-3">
                                    <Col xs={6} className="mb-3">
                                        <strong>
                                            <FaCalendarAlt className="icon" /> Starting date
                                        </strong>
                                        <div>{dayjs(arrivingDate).format("YYYY-MM-DD")}</div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <strong>
                                            <FaTimesCircle className="icon" /> End date
                                        </strong>
                                        <div>{dayjs(leavingDate).format("YYYY-MM-DD")}</div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <strong>
                                            <FaRedoAlt className="icon" /> Billing frequency
                                        </strong>
                                        <div>Monthly</div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <strong>
                                            <FaClock className="icon" /> Parking Access hours
                                        </strong>
                                        <div>7 days a week 24h a day</div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <strong>
                                            <FaDollarSign className="icon" /> First payment
                                        </strong>
                                        <div>Today</div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <strong>
                                            <FaCalendarCheck className="icon" /> Subsequent payments date
                                        </strong>
                                        <div>{dayjs(arrivingDate).format("DD")}th of each month</div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card className="summary-card">
                            <div style={{ overflow: 'hidden', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Card.Img
                                    variant="top"
                                    src={buildingImage}
                                    style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'cover', scale: '1.17' }}
                                />
                            </div>
                            <Card.Body>
                                <Card.Title className="summary-title">Parking on {buildingName}</Card.Title>
                                <div className="text-warning mb-3">
                                    ★★★★★ (17)
                                </div>
                                <h4 className="price-text">{buildingData?.price} AED / month</h4>
                                <div className="availability-text mb-3">Availability rechecked in: <strong>12:00</strong></div>
                                <Button
                                    variant="primary"
                                    style={{
                                        fontWeight: "bold",
                                        backgroundColor: "#00B8A9",
                                        border: 0,
                                    }} onClick={() => bookParking()}>Confirm and Pay</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default BookingConfirmation;