import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FaCheckCircle, FaParking, FaReceipt } from "react-icons/fa";
import "./issue.css"
import { useLocation, useParams } from "react-router-dom";
import dayjs from "dayjs";

const BookingSuccessPage = () => {
    const { state } = useLocation();
    const { id } = useParams()
    const { arrivingDate, leavingDate, buildingName } = state;

    return (
        <Container className="list-container" fluid>
            <Container>
                <Row className="justify-content-center">
                    <Col md={8}>
                        <Card className="text-center shadow-sm">
                            <Card.Body>
                                <FaCheckCircle size={70} color="#00B8A9" className="mb-4" />

                                <h2 className="section-title mb-3">
                                    Booking Confirmed! 🎉
                                </h2>
                                <p className="text-muted px-3">
                                    Thank you for booking with <strong>GCCpark</strong>. Below are the details of your
                                    booking. Please keep this information for your records.
                                </p>

                                <hr />

                                <h5 className="subsection-title mb-4">Booking Details</h5>

                                <Row>
                                    <Col xs={6} className="mb-3">
                                        <strong>Booking Reference</strong>
                                        <div>{id}</div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <strong>Starting Date</strong>
                                        <div>{dayjs(arrivingDate).format("YYYY-MM-DD")}</div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <strong>End Date</strong>
                                        <div>{dayjs(leavingDate).format("YYYY-MM-DD")}</div>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <strong>Payment Date</strong>
                                        <div>{dayjs(new Date()).format("YYYY-MM-DD")}</div>
                                    </Col>
                                </Row>

                                <hr />

                                <div className="my-4">
                                    <Button variant="primary" size="lg" className="me-3">
                                        <FaParking className="me-2" />
                                        View Parking Map
                                    </Button>
                                    <Button variant="secondary" size="lg">
                                        <FaReceipt className="me-2" />
                                        Download Receipt
                                    </Button>
                                </div>

                                <p className="text-muted mt-3">
                                    Need help? <a href="/support">Contact Support</a>
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default BookingSuccessPage;
