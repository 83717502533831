import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer id='footer' style={{ backgroundColor: '#2A4E5C', padding: '20px 0', color: '#FFFFFF' }}>
      <Container className='mt-4 text-light'>
        <Row>
          <Col md={4} className='text-center text-md-left'>
            <h5 style={{ color: '#F15A29' }}>Contact Us</h5>
            <p>Email: support@gccpark.com</p>
            <p>Phone: +971 58 252 2800</p>
          </Col>
          <Col md={4} className='text-center'>
            <h5 style={{ color: '#F15A29' }}>Follow Us</h5>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li>
                <a href='#' style={{ color: '#FFFFFF', textDecoration: 'none', marginRight: '10px' }}>Facebook</a>
              </li>
              <li>
                <a href='#' style={{ color: '#FFFFFF', textDecoration: 'none', marginRight: '10px' }}>Twitter</a>
              </li>
              <li>
                <a href='#' style={{ color: '#FFFFFF', textDecoration: 'none' }}>Instagram</a>
              </li>
            </ul>
          </Col>
          <Col md={4} className='text-center text-md-right'>
            <h5 style={{ color: '#F15A29' }}>About GCCpark</h5>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li>
                <a href='#' style={{ color: '#FFFFFF', textDecoration: 'none' }}>About us</a>
              </li>
              <li>
                <a href='#' style={{ color: '#FFFFFF', textDecoration: 'none' }}>Blogs</a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col className='text-center' style={{ borderTop: '1px solid #B3B3B3', paddingTop: '20px', marginTop: '20px', color: "#B3B3B3" }}>
            &copy; GCCpark 2024. All rights reserved.
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
