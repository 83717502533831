import React, { useState, useContext } from "react";
import easyparkAPI from '../../config/api';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../utils/AuthContext";
import { Button, Form, Card } from 'react-bootstrap';
import './AuthForm.css';

const AuthForm = ({ option, setOption }) => {
    const { state } = useLocation();
    const [credentials, setCredentials] = useState({
        email: "",
        password: "",
        repeatPassword: ""
    });

    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handleChange = (e) => {
        setCredentials(prev => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (option === 2 && credentials.password !== credentials.repeatPassword) {
            alert("Passwords do not match!");
            return;
        }

        const action = option === 1 ? "login" : "register";
        dispatch({ type: `${action.toUpperCase()}_START` });

        try {
            const endpoint = option === 1 ? "/users/login" : "/users/register";
            const res = await easyparkAPI.post(endpoint, credentials);
            sessionStorage.setItem('userLogin', JSON.stringify(res.data));
            dispatch({ type: `${action.toUpperCase()}_SUCCESS`, data: res.data });

            const redirectTo = searchParams.get("redirectTo");
            if (redirectTo) {
                navigate(redirectTo, {
                    state: { ...state }
                });
            } else {
                navigate("/");
            }
        } catch (err) {
            console.error(`Error ${action}ing user`, err);
            dispatch({ type: `${action.toUpperCase()}_FAILURE`, data: err.response?.data });
        }
    };

    return (
        <Card className='auth-form-card py-4'>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId='formEmail' className='mb-3'>
                    <Form.Control className="py-3" type='email' name='email' placeholder='Email Address' value={credentials.email} onChange={handleChange} required />
                </Form.Group>
                <Form.Group controlId='formPassword' className='mb-3'>
                    <Form.Control className="py-3" type='password' name='password' placeholder='Password' value={credentials.password} onChange={handleChange} required />
                </Form.Group>
                {option === 2 && (
                    <Form.Group controlId='formRepeatPassword' className='mb-3'>
                        <Form.Control className="py-3" type='password' name='repeatPassword' placeholder='Repeat Password' value={credentials.repeatPassword} onChange={handleChange} required />
                    </Form.Group>
                )}
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <a href='#' className='forgot-password'>Forgot password?</a>
                </div>
                <Button variant='primary' type='submit' className='w-100 auth-btn py-3'>
                    {option === 1 ? 'Login' : 'Signup'}
                </Button>
                <div className='text-center mt-3'>
                    {option === 1 ? 'Not a member? ' : 'Already have an account? '}
                    <span className='toggle-link' onClick={() => setOption(option === 1 ? 2 : 1)}>
                        {option === 1 ? 'Signup now' : 'Login now'}
                    </span>
                </div>
            </Form>
        </Card>
    );
};

export default AuthForm;
